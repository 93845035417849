import { AspectRatio, Container } from "@mantine/core";
import React from "react";

function VideoYoutube({videoYoutube}) {
  return (
    <Container size="1200px" bg="">
            <AspectRatio ratio={1920 / 1080}>
                <div className="videoFrame"
                    dangerouslySetInnerHTML={{__html: videoYoutube?.content,}}
                />
           </AspectRatio>
    </Container>
  );
}

export default VideoYoutube;
