import {
  HOME_BANNER_OPTIONS,
  fetchBanners,
} from "@/apis/queryFunctions/banner";
import { fetchCategories } from '@/apis/queryFunctions/categoryFunctions';
import { fetchEcompage } from "@/apis/queryFunctions/ecompagesFunctions";
import {
  DEFAULT_FETCHING_NEWS_OPTIONS,
  fetchNewsArticles,
} from "@/apis/queryFunctions/news";
import {
  fetchProducts,
  getBestSellerOptions,
} from "@/apis/queryFunctions/productFunctions";
import { categoryKeys, ecompagesKeys, newsKeys, productKeys } from "@/apis/queryKeys";
import bannersKeys from "@/apis/queryKeys/bannersKeys";
import ChungChi from "@/common/components/ChungChi";
import DefaultSEO from "@/common/components/DefaultSEO";
import HomeAbout from "@/common/components/HomeAbout";
import HomeBanner from "@/common/components/HomeBanner";
import HomeNews from "@/common/components/HomeNews";
import { Box, } from "@mantine/core";
import { QueryClient, dehydrate } from "@tanstack/react-query";
import React from "react";


export default function Home({ seo, data }) {
  return (
    <Box component="div" pb="lg">
      <DefaultSEO seo={seo} />
      <HomeBanner />
      <HomeAbout data={data} />
      <ChungChi />
      <HomeNews />
    </Box>
  );
}

export async function getStaticProps({ locale }) {
  const queryClient = new QueryClient();
  const newsFetchingOptions = {
    ...DEFAULT_FETCHING_NEWS_OPTIONS,
    condition: {
      ...DEFAULT_FETCHING_NEWS_OPTIONS.condition,
      ngon_ngu: locale,
    },
    notfields: ["content"],
  };

  await queryClient.prefetchQuery(newsKeys.list(newsFetchingOptions), () =>
    fetchNewsArticles(newsFetchingOptions)
  );

  /* -------------------------------------------------------------------------- */
  const categoryLv1Ids = ["TRALAI", "TRATUILOC", "TRASAMDUA", "TRABAC", "TRAOLONG", "TRAMOCCAU"];

  categoryLv1Ids.map((item) => {
    async function PrefetchQuery(id) {
      await queryClient.prefetchQuery(productKeys.homeList(id), () =>
        fetchProducts({
          options: getBestSellerOptions(id),
        })
      );
    }
    return PrefetchQuery(item);
  })

  await queryClient.prefetchQuery(bannersKeys.list("home-top-sliders-vt"), () =>
    fetchBanners(HOME_BANNER_OPTIONS)
  );
  await queryClient.prefetchQuery(ecompagesKeys.detail("du-lieu-chung-viet-thien"), () =>
    fetchEcompage("du-lieu-chung-viet-thien", locale)
  );
  await queryClient.prefetchQuery(categoryKeys.all, () => fetchCategories({ condition: { highlight: true } }))

  const data = await fetchEcompage("du-lieu-chung-viet-thien", locale);
  const sanitizedContent = (data?.add_on_data?.[0]?.content || '')
    .replace(/<[^>]*>/g, '')
    .replace(/\n/g, '')
    .replace(/&nbsp;/g, '');

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      seo: {
        title: data?.meta_title || "Nông sản Việt Thiên - Nông sản Việt vươn xa",
        description: data?.meta_description || sanitizedContent,
        keywords: data?.meta_keywords || null,
        picture1: data?.meta_picture_1 || null,
        picture2: data?.meta_picture_2 || null,
      },
      data
    },
    revalidate: 60,
  };
}
