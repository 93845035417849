import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";
import { isEmpty } from "@/utils/lodash";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import React from "react";

function DefaultSEO({ seo }) {
  // console.log(445555, seo)
  const { locale } = useRouter();
  if (!seo || isEmpty(seo)) return null;

  const images = [];

    if (seo.picture1) {
      images.push({
        url: appendImageUrlFromAPI({ src: seo.picture1, size: "xl" }),
        width: 1200,
        height: 630,
        alt: "Việt Thiên",
        type: "image/*",
      });
    } else if (seo.picture2) {
      images.push({
        url: appendImageUrlFromAPI({ src: seo.picture2, size: "xl" }),
        width: 1200,
        height: 1200,
        alt: "Việt Thiên",
        type: "image/*",
      });
    }

  return (
    <NextSeo
      title={seo?.title}
      description={seo?.description}
      openGraph={{
        url: "https://vietthien.com",
        title: seo.title,
        description: seo.description,
        images,
        locale,
        type: seo.type || "website",
        siteName: "Việt Thiên",
        article: {
          ...(seo?.article || {}),
        },
      }}
    />
  );
}

export default DefaultSEO;
