import { useFetchEcompage } from "@/apis/queryFunctions/ecompagesFunctions";
import useWindowSize from "@/hooks/useWindowSize";
import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";
import sanitizeDOMData from "@/utils/sanitizeDOMData";
import { Carousel } from "@mantine/carousel";
import { Box, Card, Container, Divider, Modal, Text, Title, } from "@mantine/core";
import { motion } from 'framer-motion';
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";

export default function ChungChi(){
    const {locale} = useRouter();
    const { data } = useFetchEcompage("du-lieu-chung-viet-thien", locale);
    const infoChungchi = data?.add_on_data?.[6]||{};
    const carouselImages = [infoChungchi?.picture, ...[ 2, 3, 4, 5, 6].map(index => infoChungchi[`picture_${index}`])];
    const { isMobile, isTablet} = useWindowSize();
    const [scrollProgress, setScrollProgress] = useState(0);
    const [embla, setEmbla] = useState(null);
    const handleScroll = useCallback(() => {
        if (!embla) return;
        const progress = Math.max(0, Math.min(1, embla.scrollProgress()));
        setScrollProgress(progress * 100);
      }, [embla, setScrollProgress]);
    
      useEffect(() => {
        if (embla) {
          embla.on('scroll', handleScroll);
          handleScroll();
        }
      }, [embla]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
      };
    
      const handleCloseModal = () => {
        setSelectedImageIndex(null);
      };
  return(
    <Box  sx={{ align:"center", backgroundColor: "#F8F8F8", position:"relative", }}>
      <Container size={1440} sx={{background:"#F8F8F8", paddingTop:" 20px", paddingBottom:"20px"}}>
          <Container>
          <Divider
                my="sm"
                label={<Title order={2} size= {isTablet ? "24px" : "36px"} color="#12854F" fw={950} pb={10} ta="center">{sanitizeDOMData(infoChungchi?.title)}</Title>}
                    labelPosition="center"
          />
          <Text fw={300} size={18} color="#000" ta={isTablet?"justify":"center"}>{sanitizeDOMData(infoChungchi?.content)}</Text>
        </Container>         
               
          <Box sx={{ position: "relative", paddingTop:"30px", textAlign:"center", }}>
            <Box sx={{ position: "relative", paddingTop:"10px", textAlign:"center"}}>         
                <Carousel
                  dragFree
                  slideSize= {isTablet? "50%" : "20%"}
                  slideGap="xs"
                  height={isMobile? 300 : 400}
                  getEmblaApi={setEmbla}
                  initialSlide={2}
                  mx="auto"
                  withIndicators
                  styles={{
                    indicator: {
                      backgroundColor:"#FFF",
                      width: 12,
                      height: 12,
                      transition: 'width 250ms ease',
                      border: '1px solid black',
            
                      '&[data-active]': {
                        width: 40,
                        backgroundColor: '#12854F',
                      },
                    },
                  }}
                >
                  {carouselImages.map((image, index) => (
                          <Carousel.Slide key={index}>
                            <Card onClick={() => handleImageClick(index)} sx={{
                              cursor:'pointer',
                              transition: "transform 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.01)",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                              },
                            }}>
                              <motion.div whileHover={{scale: 1.05}}>             
                                <Image src={appendImageUrlFromAPI({ src: image, size: "xl" })} width= "233px" height= "330px" alt={`Hình ảnh chứng chỉ ${index + 1}`} title={`Hình ảnh chứng chỉ ${index + 1}`} objectFit="cover" loading="lazy"/>
                              </motion.div>  
                            </Card> 
                            <Modal opened={selectedImageIndex === index} onClose={handleCloseModal} centered>
                              <Image src={appendImageUrlFromAPI({ src: image, size: "xl" })} width= "500px" height= "700px" alt={`Hình ảnh chứng chỉ ${index + 1}`} title={`Hình ảnh chứng chỉ ${index + 1}`} objectFit="contain" loading="lazy"/>
                            </Modal> 
                          </Carousel.Slide>
                          ))}
                </Carousel>
           </Box>
        </Box>
      </Container>  
    </Box>      
    )
}
