import useWindowSize from "@/hooks/useWindowSize";
import sanitizeDOMData from "@/utils/sanitizeDOMData";
import { Carousel } from "@mantine/carousel";
import { Box, Container, Divider, Title } from "@mantine/core";
import { motion, useAnimation } from 'framer-motion';
import Image from "next/image";
import logo3sach from "public/logo/logo_doitac/3sachfood.jpg";
import logoBachHoaXanh from "public/logo/logo_doitac/bachhoaxanh.webp";
import logoBigC from "public/logo/logo_doitac/big-c.webp";
import logoCafeControl from "public/logo/logo_doitac/cafecontrol.webp";
import logoJmart from "public/logo/logo_doitac/j-mart.webp";
import logoMacnels from "public/logo/logo_doitac/mn-shipping.webp";
import logoSqs from "public/logo/logo_doitac/sgs.webp";
import logoSatraFoods from "public/logo/logo_doitac/strafoods.webp";
import logoVcc from "public/logo/logo_doitac/vcc-c.webp";
import logoVinaControl from "public/logo/logo_doitac/vinacontrol.webp";
import logoVissan from "public/logo/logo_doitac/vissan-logo.webp";
import React, { useEffect, useState } from "react";


function DoiTac({doiTac}){
    const images = [ logoVcc, logoCafeControl, logoVinaControl, logoSqs, logoMacnels, logoBachHoaXanh, logoBigC, logoVissan, logo3sach, logoJmart, logoSatraFoods];
    const imageWidth = 200;
    const controls = useAnimation();
    const {isMobile, isTablet} = useWindowSize();
    const animationDuration = 1.5; 
    const [currentIndex, setCurrentIndex] = useState(0);

    const startAnimation = async () => {
      while (true) {
        for (let i = 0; i < images.length; i++) {
          setCurrentIndex(i);
          await new Promise(resolve => setTimeout(resolve, animationDuration * 1000));
        }
      }
    };
  
    useEffect(() => {
      startAnimation();
    }, []); 
  
    useEffect(() => {
      controls.start({ x: -currentIndex * imageWidth, transition: { duration: 0.9 } });
    }, [currentIndex, controls]);
    
    return(
        <Container size="xl" sx={{position: "relative", paddingBottom: "70px",}}>
          <Divider my="sm"
                    label={<Title order={2} size={isTablet ? "24px" : "36px"} color="#12854F" weight={950} ta="center" pb={10} >{sanitizeDOMData(doiTac?.title)}</Title>}
                    labelPosition="center"
          /> 

          <Box sx={{ position: 'relative', paddingTop: '30px', textAlign: 'center' }}>
              <Carousel
                dragFree
                slideSize={isTablet ? '50%' : '20%'}
                slideGap="xl"
                height={isMobile ? 100 : 100}
                initialSlide={0}
                mx="auto"
                controls={false}
                style={{ overflow: 'hidden' }}
              >
                {images.map((image, index) => (
                  <Carousel.Slide key={index}>
                    <motion.div
                      style={{ display: 'inline-block', width: imageWidth }}
                      initial={{ x: 0 }}
                      animate={controls}
                      >
                      <Image src={image} width={imageWidth} height={80} alt={`Logo doanh nghiệp đối tác ${index + 1}`} title={`Logo doanh nghiệp đối tác ${index + 1}`} loading="lazy"/>
                    </motion.div>
                  </Carousel.Slide>
                ))}
              </Carousel>
          </Box>
      </Container>
    )
}
export default DoiTac;