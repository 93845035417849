import useTrans from '@/hooks/useTrans';
import useWindowSize from '@/hooks/useWindowSize';
import appendImageUrlFromAPI from '@/utils/appendImageUrlFromAPI';
import sanitizeDOMData from '@/utils/sanitizeDOMData';
import { Box, Container, Divider, Grid, Text, Title } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import VideoYoutube from '../videoYoutube';
import DoiTac from './DoiTac';

function HomeAbout({ data }) {
  const trans = useTrans();
  const { isTablet } = useWindowSize();
  const info = (data?.add_on_data || []).slice(0, 7);
  const products = [info[1], info[2], info[3]];
  const homeData = [
    { alt: 'trà Việt Thiên', href: '/san-pham/tra' },
    { alt: 'Zemor', href: '/san-pham/ca-phe' },
    { alt: 'nông sản Việt Thiên', href: '/san-pham/nong-san' },
  ];

  return (
    <div>
      <Box component="section">
        <Container size="xl">
          <Box sx={{ position: 'relative', paddingTop: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js" />
            <lottie-player
              src="https://lottie.host/fd94a67e-99a0-4c54-aaa6-6cc477b6fa9a/CbITNSsKHO.json"
              background="#fff"
              speed="1"
              style={{ width: isTablet ? '150px' : '163px', height: isTablet ? '150px' : '163px' }}
              loop
              autoplay
              direction="1"
              mode="normal"
            />
          </Box>

          <Container sx={{ position: 'relative', paddingTop: '50px', paddingBottom: '50px', textAlign: 'center' }}>
            <Text fw={300} size={24} color="#000" ta={isTablet ? 'justify' : 'center'}>
              {sanitizeDOMData(info[0]?.content)}
            </Text>
          </Container>

          <Divider
            my="sm"
            label={<h1 style={{ fontSize: isTablet ? '24px' : '36px', color: '#12854F', marginBottom: '5px' }} className="webview">{trans?.homeAbout?.product}</h1>}
            labelPosition="center"
          />

          {products &&
            products.map((item, i) => (
              <Grid grow gutter="xl" justify="space-between" align="center" key={i}>
                <Grid.Col xs={12} md={7} sx={{}} orderMd={i % 2 !== 0 ? 2 : 1}>
                  <Box sx={{ position: 'relative', aspectRatio: '1.2' }}>
                    <Image
                      src={appendImageUrlFromAPI({ src: item?.picture, size: 'l' })}
                      alt={homeData[i].alt}
                      title={homeData[i].alt}
                      layout="fill"
                      objectFit="contain"
                      loading="lazy"
                    />
                  </Box>
                </Grid.Col>
                <Grid.Col xs={12} md={5} sx={{}} align={isTablet ? 'center' : 'justify'} orderMd={i % 2 !== 0 ? 1 : 2}>
                  <Title order={2} size="24px" weight="900" color="#212F3B">
                    {sanitizeDOMData(item?.title)}
                  </Title>
                  <Text ta="justify">{sanitizeDOMData(item?.content)}</Text>
                  <Link scroll={false} href={homeData[i].href}>
                    <a target="_blank">
                      <button className="btn">
                        <Text size={16} weight={700} display="inline-flex">
                          {trans?.homeAbout.seeMore}
                          <IconArrowRight size={20} style={{ margin: '3px 0 0 10px' }} />
                        </Text>
                      </button>
                    </a>
                  </Link>
                </Grid.Col>
              </Grid>
            ))}
        </Container>
      </Box>

      <Box pt={30} sx={{ position: 'relative', paddingBottom: '50px' }}>
        <VideoYoutube videoYoutube={info[4]} />
      </Box>

      <DoiTac doiTac={info[5]} />
    </div>
  );
}

export default HomeAbout;

// CSS for the button
const btnStyle = `
.btn {
  --color: #12854F;
  --color2: rgb(10, 25, 30);
  padding: 0.3em 1.75em;
  background-color: transparent;
  border-radius: 6px;
  border: 0.3px solid var(--color);
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  font-weight: 300;
  font-size: 17px;
  font-family: 'Roboto', 'Segoe UI', sans-serif;
  text-transform: uppercase;
  color: var(--color);
}

.btn::after,
.btn::before {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  transform: skew(90deg) translate(-50%, -50%);
  position: absolute;
  inset: 50%;
  left: 25%;
  z-index: -1;
  transition: 0.5s ease-out;
  background-color: var(--color);
}

.btn::before {
  top: -50%;
  left: -25%;
  transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
}

.btn:hover::before {
  transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
}

.btn:hover::after {
  transform: skew(45deg) translate(-50%, -50%);
}

.btn:hover {
  color: var(--color2);
}

.btn:active {
  filter: brightness(0.7);
  transform: scale(0.98);
}
`;

if (typeof window !== 'undefined') {
  const style = document.createElement('style');
  style.innerHTML = btnStyle;
  document.head.appendChild(style);
}
